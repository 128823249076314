/**
 * Import media breakpoints and other shared vars (js/css)
 */
@import 'shared-vars';
@import 'vars-figma-colors';

/**
 * Use autogenerated media in media mixins
 */
$media-steps: $sx-breakpoints;

$size-xxxs: pxToRem(8);
$size-xxs: pxToRem(16);
$size-xs: pxToRem(28);
$size-sm: pxToRem(40);
$size-md: pxToRem(48);
$size-lg: pxToRem(64);
$size-xl: pxToRem(96);
$size-xxl: pxToRem(128);
$size-xxxl: pxToRem(192);

$grid: (
    steps: (1, 12),
    gutters: (10, 20),
    breakpoint-gutters: (
        'xs': 10px,
        'sm': 10px,
        'md': 20px,
        'lg': 28px,
        'xl': 28px,
        'xxl': 28px,
    ),
    container-paddings: (
        'xs': 14px,
        'sm': 14px,
        'md': 60px,
        'lg': 60px,
        'xl': 92px,
        'xxl': 92px,
    ),
    container-paddings-fluid: (
        'xs': 20px,
        'sm': 20px,
        'md': 60px,
        'lg': 60px,
        'xl': 60px,
        'xxl': 60px,
    )
);

$image-ratio-steps: (
    1, 2, 3, 4, 5, 9, 16
);

$sx-path-images: '/dist/img/'; // Dies ist eine doppelte Definition, die weiter unten nochmal vorkommt.

/* Header heights kommen aus shared_vars.json */
$header-height: $sx-headerheight; // Wird unten überschrieben.
$header-height-mobile: $sx-headerheight-mobile;


/**
 * Spacing vars
 */
$spacing-m: $size-xs; // TODO @zelli (?) aus der Filterbase bitte rausnehmen!

$modulspacing-desktop: $size-xl;
$modulspacing-desktop-m: $modulspacing-desktop; // Standard
$modulspacing-mobile-m: $size-md; // Standard


/**
 * Color vars
 */
$color-secondary-bg: #fff;
$color-primary-bg-hover: #fff;


/**
 * Border radius vars
 */
$border-radius: 0px !default;
$border-radius-button: 5px;
/* If border-radius-button is not defined somewhere else, use border-radius as default */
$border-radius-button: $border-radius !default;


/* Alte Werte, die ggf. nicht benötigt werden */
/**
 * Sx vars
 */

/**
 * Spacing vars
 */

$spacing-xxs: $size-xxxs; //8px
$spacing-xs: $size-xxs; //16p
$spacing-s: $size-xs; //28px
$spacing-m: pxToRem(36); //36p
$spacing-l: $size-md; //48px
$spacing-xl: $size-lg; //64p
$spacing-xxl: $size-xl; //96px

$spacing-mobile-s: pxToRem(12); //12px
$spacing-mobile-m: pxToRem(56); //56px

/**
 * Font vars
 */
$sx-path-fonts: '/dist/font/';
$font01: 'Montserrat', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$font02: '---', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

$font-family-primary: $font01;
$font-family-secondary: $font02;

$base-font-size: 16px;
$base-font-weight: 400;


/**
 * Color vars
 */
$font-color-light: #FFFFFF;
$font-color: #374A54;
$font-color-dark: #000000;

$font-color-primary: $font-color;
$font-color-secondary: $font-color; // ggf. für Headline etc.

$color-primary-light: #C6DAE6;
$color-primary: #977316;
$color-primary-dark: #48606F;

$color-secondary-light: #E6E0D8;
$color-secondary: #001112;
$color-secondary-dark: #FDB43A;
$color-secondary-700: #606162;

$color-primary-hover: $color-secondary;
$color-secondary-hover: $color-primary-hover;

$color-tertiary-light: #FBF6EE;
$color-tertiary: #FBF6EE;

$color-background: #333C40;

$color-success: $color-success-800;
$color-warning: $color-warning-800;
$color-danger: $color-error-800;
$color-info: #37C4EF;

$color-white: #FFFFFF;
$color-black: #000000;

$color-light: #F9FCFF;
$color-dark: #4B525B;

$color-gray-05: mix($color-black, $color-white, 0.05 * 100%);
$color-gray-10: mix($color-black, $color-white, 0.10 * 100%);
$color-gray-15: mix($color-black, $color-white, 0.15 * 100%);
$color-gray-20: mix($color-black, $color-white, 0.20 * 100%);
$color-gray-25: mix($color-black, $color-white, 0.25 * 100%);
$color-gray-30: mix($color-black, $color-white, 0.30 * 100%);
$color-gray-35: mix($color-black, $color-white, 0.35 * 100%);
$color-gray-40: mix($color-black, $color-white, 0.40 * 100%);
$color-gray-45: mix($color-black, $color-white, 0.45 * 100%);
$color-gray-50: mix($color-black, $color-white, 0.50 * 100%);
$color-gray-55: mix($color-black, $color-white, 0.55 * 100%);
$color-gray-60: mix($color-black, $color-white, 0.60 * 100%);
$color-gray-65: mix($color-black, $color-white, 0.65 * 100%);
$color-gray-70: mix($color-black, $color-white, 0.70 * 100%);
$color-gray-75: mix($color-black, $color-white, 0.75 * 100%);
$color-gray-80: mix($color-black, $color-white, 0.80 * 100%);
$color-gray-85: mix($color-black, $color-white, 0.85 * 100%);
$color-gray-90: mix($color-black, $color-white, 0.90 * 100%);
$color-gray-95: mix($color-black, $color-white, 0.95 * 100%);

$color-white-light: lighten($color-white, 5%) !default;
$color-white-lighter: lighten($color-white, 10%) !default;

$color-white-dark: darken($color-white, 5%) !default;
$color-white-darker: darken($color-white, 10%) !default;

$color-black-light: lighten($color-black, 5%) !default;
$color-black-lighter: lighten($color-black, 10%) !default;

$color-black-dark: darken($color-black, 5%) !default;
$color-black-darker: darken($color-black, 10%) !default;

$color-light-light: lighten($color-white, 5%) !default;
$color-light-lighter: lighten($color-white, 10%) !default;

$color-light-dark: darken($color-white, 5%) !default;
$color-light-darker: darken($color-white, 10%) !default;

$color-dark-light: lighten($color-white, 5%) !default;
$color-dark-lighter: lighten($color-white, 10%) !default;

$color-dark-dark: darken($color-white, 5%) !default;
$color-dark-darker: darken($color-white, 10%) !default;

$color-primary-light: lighten($color-primary, 5%) !default;
$color-primary-lighter: lighten($color-primary, 10%) !default;

$color-primary-dark: darken($color-primary, 5%) !default;
$color-primary-darker: darken($color-primary, 10%) !default;

$color-secondary-light: lighten($color-secondary, 5%) !default;
$color-secondary-lighter: lighten($color-secondary, 10%) !default;

$color-secondary-dark: darken($color-secondary, 5%) !default;
$color-secondary-darker: darken($color-secondary, 10%) !default;

$color-success-light: lighten($color-success, 5%) !default;
$color-success-lighter: lighten($color-success, 10%) !default;

$color-success-dark: darken($color-success, 5%) !default;
$color-success-darker: darken($color-success, 10%) !default;

$color-warning-light: lighten($color-warning, 5%) !default;
$color-warning-lighter: lighten($color-warning, 10%) !default;

$color-warning-dark: darken($color-warning, 5%) !default;
$color-warning-darker: darken($color-warning, 10%) !default;

$color-danger-light: lighten($color-danger, 5%) !default;
$color-danger-lighter: lighten($color-danger, 10%) !default;

$color-danger-dark: darken($color-danger, 5%) !default;
$color-danger-darker: darken($color-danger, 10%) !default;

$color-info-light: lighten($color-info, 5%) !default;
$color-info-lighter: lighten($color-info, 10%) !default;

$color-info-dark: darken($color-info, 5%) !default;
$color-info-darker: darken($color-info, 10%) !default;

$color-background-light: lighten($color-background, 10%) !default;
$color-background-lighter: lighten($color-background, 20%) !default;

$color-background-dark: darken($color-background, 10%) !default;
$color-background-darker: darken($color-background, 20%) !default;