
* {
    outline: none !important;
}

*, *::before, *::after {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html, body {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $base-font-size;
    font-variant-numeric: normal;
    font-feature-settings: normal;
    line-height: 1.5;
}

body {
    overflow-x: hidden;
    font-family: $font01;
    font-weight: 400;
    color: $font-color;
}

.css-transitions-only-after-page-load *:not(img,picture) {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
}

.wrapper {
    visibility: visible !important;
}

a {
    color: $color-primary;
    text-decoration: none;
}

.text-center {
    text-align: center;
}

//h1, .h1 {
//    font-size: pxToRem(48);
//    line-height: 120%;
//    font-weight: 300;
//}
//
//
//h2, .h2 {
//    font-size: pxToRem(46);
//    line-height: 120%;
//    font-weight: 300;
//}
//
//h3, .h3 {
//    font-size: pxToRem(36);
//    line-height: 120%;
//    font-weight: 300;
//}
//
//h4, .h4 {
//    font-size: pxToRem(28);
//    line-height: 120%;
//    font-weight: 300;
//}
//
//h5, .h5 {
//    font-size: pxToRem(26);
//    line-height: 120%;
//    font-weight: 300;
//}
//
//h6, .h6 {
//    font-size: pxToRem(18);
//    line-height: 120%;
//    font-weight: 300;
//}

p {
    line-height: 150%;
}

address {
    font-style: normal;
}

.highlights {
    column-count: 3;

    & + .highlights__socials {
        margin-top: pxToRem(36);
    }
}

.highlight__item {
    position: relative;
    padding-left: pxToRem(36);
    margin-bottom: 10px;
    font-weight: 700;
    color: $font-color-light;

    &::marker {
        content: none;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 24px;
        height: 24px;
        background-color: $color-primary;
        background-image: inline-svg('arrow', $color-white);
        background-repeat: no-repeat;
        background-size: 10px 10px;
        background-position: center center;
        border-radius: 50%;
    }
}

table {
    display: block;
    position: relative;
    padding-bottom: 4px;
    background:
            linear-gradient(270deg,
                    white 30%,
                    rgba(255, 255, 255, 0)
            ) right center,

            radial-gradient(
                            farthest-side at 100% 50%,
                            rgba(0, 0, 0, 0.08),
                            rgba(0, 0, 0, 0.0)
            ) right center;
    background-repeat: no-repeat;
    background-size: 110px 100%, 50px 200%;
    background-attachment: local, scroll;
    overflow-x: auto;
}

tbody {
    display: table;
    overflow-x: auto;
    width: 100%;
}

@include media-breakpoint-down(md) {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        hyphens: auto;
    }

    .highlights {
        column-count: 2;
    }
}

@include media-breakpoint-down(sm) {
    .highlights {
        column-count: 1;
    }
}

@include media-breakpoint-down(md) {
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        hyphens: auto;
    }
}
