.container--fluid {
    max-width: inherit !important;
}

.article__intro {
    margin: $spacing-xxl 0;
}

.slider-big + #article__intro .article__intro {
    margin-top: $spacing-mobile-m;
}

.headline {
    color: $font-color-dark;
}

.headline--topline {
    margin-bottom: $spacing-s;
    text-decoration: underline;
}

.article__body {
    margin: $spacing-l 0 0 $spacing-xl;
}

.cta__button {
    margin-top: $spacing-l;
}

.price {
    color: $font-color-dark;
}

.highlights__bg {
    padding: $spacing-l 0;
    background-color: $color-secondary;
}

.content__highlights {
    padding: $spacing-l 0;
    margin: initial;

    ul {
        column-gap: 20px;
        column-count: 3;
        color: $font-color-light;
    }
}

.link__container {
    display: flex;
    padding: $spacing-l 0;
    margin-bottom: $spacing-xxl;
    background-color: $color-secondary-700;
    .container {
        display: flex;
    }
}

.link__website, .link__booking {
    &::before {
        background-image: inline-svg('arrow-long', $color-secondary);
        //transform: rotate(90deg);
    }
}

.button:not(.button--link).link__website {
    background-color: $color-secondary;
}

.button:not(.button--link).link__booking {
    background-color: $color-primary;
}

@include media-breakpoint-down(sm) {
    .article__intro {
        margin: $spacing-mobile-m 0;
    }

    .article__body {
        margin: $spacing-xs 0 0 12px;
    }

    .article__intro .headline--headline {
        margin-bottom: $spacing-s;
    }

    .link__container .container {
        flex-wrap: wrap;
        justify-content: center;
    }
}

/*** || Socials ***/

.socials {
    display: flex;
    justify-content: center;
    column-gap: pxToRem(16);

    a {
        max-width: pxToRem(36);
    }
}