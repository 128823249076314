@mixin SxEditor {

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6, p, ul, ol, table {
        //font-weight: 400;
    }

    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        line-height: 120%;
        font-weight: 300;
    }

    h1, .h1, h2, .h2 {
        margin-bottom: $spacing-l;
    }

    h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
        margin-bottom: pxToRem(12);
    }

    h1, .h1 {
        font-size: pxToRem(48);
    }

    h2 {
        font-size: pxToRem(26);
    }

    .h2 {
        font-size: pxToRem(46);
    }

    h3 {
        font-size: pxToRem(22);
    }

    .h3 {
        font-size: pxToRem(36);
    }

    h4 {
        font-size: pxToRem(20);
    }

    .h4 {
        font-size: pxToRem(28);
    }

    h5 {
        font-size: pxToRem(18);
    }

    .h5 {
        font-size: pxToRem(26);
    }

    h6 {
        font-size: pxToRem(16);
    }

    .h6 {
        font-size: pxToRem(18);
    }

    @include media-breakpoint-down(sm) {
        h1, .h1 {
            font-size: pxToRem(40);
        }
    }

    p {
        margin-top: pxToRem(12);
        line-height: 150%;
    }

    p, ul, ol, hr, table {
        margin-bottom: $spacing-s;
    }

    hr {
        // border-top: 1px solid $color-dark;
        width: 60px;
        height: 2px;
        margin: $spacing-s 0;
        background-color: $color-secondary;
    }

    .content a, &.content a {
        color: $color-primary;

        &:hover, &:focus {
            text-decoration: underline;
        }
    }

    ol, ul {
        margin-top: $spacing-s;
    }

    ol {
        list-style-type: decimal;
        padding-left: pxToRem(18);
    }

    ol li {
        position: relative;
        padding-left: pxToRem(10);
    }

    ul {
        //padding-left: pxToRem(24);
        list-style-type: none !important;
    }

    ul li {
        padding-left: pxToRem(22);
        position: relative;

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    ul li:before {
        content: '';
        position: absolute;
        top: 0.5rem;
        left: 0;
        display: block;
        width: 6px;
        height: 6px;
        background-color: #808080;
        border-radius: 50%;
    }

    .list--highlight {
        padding-left: pxToRem(36);
        font-weight: 700;

        &::marker {
            content: none;
        }

        &::before {
            content: '';
            position: absolute;
            top: 0;
            display: block;
            width: 24px;
            height: 24px;
            background-color: $color-primary;
            background-image: inline-svg('arrow', $color-white);
            background-repeat: no-repeat;
            background-size: 10px 10px;
            background-position: center center;
            border-radius: 50%;
        }
    }

    ul li::marker,
    ol li::marker {
        font-size: pxToRem(16);
        color: $color-primary;
    }

    ol ol, ul ul, ol ul, ul ol {
        margin-bottom: 0;
    }

    table {
        text-align: left;
        //border: 1px solid $color-gray-15;
        width: 100%;
        margin: $spacing-s 0;
    }

    table th,
    table td,
    table thead th,
    table tbody td {
        text-align: left;
        background: transparent;
    }

    thead th {
        color: $font-color-dark;
    }

    th:first-child, td:first-child {
        padding-left: 0;
    }

    tr:not(:last-child) {
        border-bottom: 1px solid $color-secondary;
    }

    thead tr:last-child {
        border-bottom: 2px solid $color-secondary;
    }

    th, td {
        padding: 8px 12px;
    }

    th:not(:last-child),
    td:not(:last-child) {
        //border-right: 1px solid $color-gray-10;
    }
    em {
        font-style: italic;
    }

}
