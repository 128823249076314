.teaser-slider + .footer {
    margin-top: -60px;
}

.footer__top {
    width: 100%;
    height: auto;
    padding: $spacing-l 0;
    font-size: pxToRem(16);
    color: $font-color-light;
    background-color: $color-secondary;

    a {
        color: $font-color-light;
    }
}

.footer__container {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: wrap;
    justify-content: space-between;
}

/* || Kontakt */

.contact__container {
    display: flex;
    flex-direction: column;
}

.contact__name {
    font-size: pxToRem(20);
    font-weight: 700;
}

.contact__address {
    margin-top: 8px;
}

.contact__phone, .contact__email {
    font-weight: 700;

    a {
        margin-left: $spacing-xs;
        font-weight: 400;
    }
}

.contact__phone {
    margin-top: $spacing-s;
}

.contact__email {
    a {
        text-decoration: underline;
    }
}

/* || Newsletter */

.footer__newsletter--headline {
    font-size: pxToRem(20);
    font-weight: 700;
}

.footer__newsletter--content {
    margin-top: $spacing-xxs;
}

.footer-newsletter__form {
    margin-top: $spacing-m;

    .input-group > .input-group-append > .button {
        &:hover, &:focus {
            background-color: $color-primary;
        }
    }
}

.footer__newsletter .footer-newsletter__input {
    height: 50px;
    margin-right: $spacing-xxs;
    color: $font-color-light;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid $color-white;
    background-image: inline-svg('email', $color-white, transparent);
    background-position: 6px center;
    background-size: 24px 24px;
    background-repeat: no-repeat;
    text-indent: $spacing-m;
    transition: all .3s ease;

    &::placeholder {
        color: $font-color-light;
    }

    &:hover, &:focus {
        border: none;
        border-bottom: 1px solid $color-primary;
        background-color: transparent;
    }
}

.footer__socials {
    margin-top: pxToRem(28);
}

/* || Navigation */

.footer__navigation {
    margin-top: $spacing-s;
}

.footer__navigation ul {
    display: flex;
    flex-wrap: wrap;
    row-gap: pxToRem(16);
}

.footer__navigation ul li {
    &:not(:last-child) {
        margin-right: $spacing-l;
    }
}

.footer__navigation ul li a {
    position: relative;
    display: flex;
    align-items: center;
    font-size: pxToRem(16);
    transition: all .3s ease;

    &::after {
        content: '';
        position: relative;
        display: inline-block;
        width: 22px;
        height: 22px;
        margin-left: $spacing-xxs;
        background-color: $color-primary;
        background-image: inline-svg('arrow', $color-white);
        background-size: 10px 10px;
        background-position: center center;
        background-repeat: no-repeat;
        transition: all .3s ease;
    }

    &:hover {
        text-decoration: underline;
    }

    &:hover::after {
        //background-color: $color-primary-hover;
        background-image: inline-svg('arrow', $color-white);
    }
}

/* || Logoleiste */

.footer__bottom {
    display: flex;
    flex-wrap: wrap;
    background-color: $color-secondary-700;
}

.logo__item {
    display: flex;
    flex: 1 1 auto;
    justify-content: center;
    align-items: center;
    padding: $spacing-m;

    &:not(:last-child) {
        border-right: 1px solid $font-color;
    }

    &--1 {
        max-width: 138px;
    }

    &--2 {
        max-width: 138px;
    }

    &--3 {
        max-width: 198px;
    }

    &--4 {
        max-width: 148px;
    }

    &--5 {
        max-width: 137px;
    }
}

//.logo__item--1 img {
//    width: 139px;
//    height: auto;
//}
//
//.logo__item--2 img {
//    width: 161px;
//    height: auto;
//}
//
//.logo__item--3 img {
//    width: 199px;
//    height: auto;
//}
//
//.logo__item--4 img {
//    width: 149px;
//    height: auto;
//}
//
//.logo__item--5 img {
//    width: 139px;
//    height: auto;
//}

@include media-breakpoint-up(lg) {
    .footer__contact {
        padding-right: pxToRem(56);
    }
}

@include media-breakpoint-down(lg) {
    .logo__item:nth-child(even) {
        border-right: none;
    }

    .logo__item:last-child {
        border-right: 1px solid $color-black;
    }

    .logo__item:not(:last-child) {
        border-bottom: 1px solid $color-black;
    }

    .footer__contact {
        margin-top: $spacing-m;
    }
}

@include media-breakpoint-down(md) {
    .footer__navigation ul li {
        margin-top: $spacing-xs;

        &:not(:last-child) {
            margin-right: $spacing-s;
        }
    }
}

@include media-breakpoint-down(sm) {
    .footer__bottom .logo__item {
        border-right: none;
    }
}