.header {
    position: absolute;
    z-index: 50;
    left: 0;
    top: 0;
    width: 100%;
    height: $header-height;
    background: $color-white;
    box-shadow: 0 0 3px rgba(0,0,0,0.160);
}


.header > .container,
.header > .container > .grid {
    height: 100%;
}

@include media-breakpoint-up(lg) {
    .js-nav-scrolled .header {
        transform: translateY(-100%);
    }
}

.js-nav-scrolled02 .header {
    transform: translateY(0);
    transition: all 1s ease;
    position: fixed;
}

.header__language ul {
    width: calc(100% + 4px);
    margin: 0 -2px;
}

.header__language ul > li {
    display: inline-block;
    padding: 0 2px;
}

.header__language ul > li:not(:last-child):after {
    content: '|';
    display: inline-block;
    margin-left: 4px;
    color: $color-gray-25;
}

.header__button-container {
    position: absolute;
    bottom: 0;
    left: 50%;
    display: flex;
    transform: translate(-50%, 100%);
    z-index: 10;
}

.js-nav-scrolled .header__button-container {
    opacity: 0;
}

.js-nav-scrolled02 .header__button-container {
    opacity: 1;
    transition: all .3s ease;
}

.header__button--gutscheine, .header__button--booknow {
    &::before {
        background-image: inline-svg('arrow-long', $color-white);
        //transform: rotate(90deg);
    }
}

.button:not(.button--link).header__button--gutscheine {
    background-color: $color-secondary;
    transition: transform .3s ease, background-color .3s ease;
}

.button:not(.button--link).header__button--booknow {
    background-color: $color-primary;
    transition: transform .3s ease, background-color .3s ease;
}

.header__logo {
    //margin-right: auto;
    margin-right: auto;
    flex: 1 1 auto;

    a {
        display: inline-block;
    }
}

.header__logo .logo--d {
    display: block;
    max-width: 174px;
}

.header__logo .logo--scrolled {
    display: none;
    width: 64px;
    height: auto;
}

.js-nav-scrolled .header {
    transform: translateY(-100%);
}

.js-nav-scrolled .header__language {
    margin-right: pxToRem(36);
    display: none;
}

.js-nav-scrolled02 {
    .header {
        height: 80px;
        background-color: rgba($color-black, .35);
        -webkit-backdrop-filter: blur(6px);
        backdrop-filter: blur(6px);
        transform: translateY(0);
        box-shadow: 0 0 4px rgba($color-dark, 0.15);
    }

    .header__logo {
        width: 99px;
        height: auto;
    }

    .header__logo .logo--d {
        display: none;
    }

    .header__logo .logo--scrolled {
        display: block;
    }

    @media(min-width: 1300px) {
        .header__button-container {
            position: relative;
            left: initial;
            //right: 50px;
            transform: none;
        }
        .button.button--large:not(.button--link).header__button--gutscheine {
            width: auto;
            height: auto;
            padding: initial;
            margin-right: $spacing-xxs;
            background: none;
            font-size: pxToRem(16);
            color: $font-color-light;

            &::before {
                content: none;
            }

            &::after {
                content: '';
                flex: initial;
                width: 36px;
                height: 36px;
                margin-left: 16px;
                background-color: $color-primary;
                background-image: inline-svg('arrow', $color-white);
                transform: none;
                transition: all .3s ease;
            }

            &:hover::after, &:focus::after {
                flex: initial;
                background-color: $color-secondary-hover;
                background-image: inline-svg('arrow', $color-white);
            }
        }
        .button.button--large:not(.button--link).header__button--booknow {
            width: auto;
            height: 36px;
            padding: 7px 15px 8px 16px;
            font-size: pxToRem(16);
            background-color: $color-primary;

            span {
                transition: all .3s ease;
            }

            &::before {
                content: none;
            }

            &::after {
                flex: initial;
                background-image: inline-svg('arrow', $color-white);
                transition: all .3s ease;
            }

            &:hover, &:focus {
                background-color: $color-secondary-hover;

                span {
                    transform: translateX(-4px);
                }
            }

            &:hover::after, &:focus::after {
                background-image: inline-svg('arrow', $color-white);
                transform: translateX(4px);
            }
        }
    }
}

@media(max-width: 1300px) {
    .header {
        height: 110px;
    }

    .header__logo {
        margin-right: initial;
        margin-left: auto;
        flex: initial;
    }

    .language {
        margin-top: pxToRem(32);

        .language__item {
            color: $color-white;

            &.language__item--current {
                color: $color-primary;
            }

            a {
                padding: 0 pxToRem(8);
            }
        }
    }
}

@include media-breakpoint-down(xl) {
    //.js-nav-scrolled02 .header__button-container {
    //    left: 30px;
    //    order: -1;
    //}
}

@include media-breakpoint-down(md) {
    //.js-nav-scrolled02 .header__button-container {
    //    left: 60px;
    //}
}

@include media-breakpoint-down(sm) {
    .button.button--large.button--booking:not(.button--link).header__button--gutscheine, .button.button--large.button--booking:not(.button--link).header__button--booknow {
        width: 100%;

        &::after {
            content: '';
        }
    }

    .header {
        height: 80px;
    }

    .header__logo img.logo--d {
        height: 50px;
    }
}

@media(max-width: 340px) {
    .button.button--large.button--booking:not(.button--link).header__button--gutscheine, .button.button--large.button--booking:not(.button--link).header__button--booknow {
        white-space: normal;
    }
}