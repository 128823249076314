.list__pagination {
    margin-top: 20px;
}

.list {
    background-color: $color-secondary-light;
    padding: $spacing-l 0 $spacing-l;
    margin: $spacing-xxl 0;
}

.list-item__container, .filter-item__container {
    display: flex;
    background-color: $color-white;
    transition: all .3s ease;

    &:not(:last-child) {
        margin-bottom: $spacing-s;
    }

    &:hover {
        background-color: $color-white;
        box-shadow: 0 2px 36px rgba($color-black, .12);
    }
}

.list__item, .filter__item {
    display: flex;
}

.list__body, .filter-item__body {
    display: flex;
    flex: 1;
    padding: $spacing-xs 0 $spacing-xs $spacing-s;
}

.list-item__content, .filter-item__content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding-right: $spacing-xs;
    //margin-right: $spacing-xs;
    border-right: 1px solid $color-black;
}

.list-item__headline, .filter-item__headline {
    margin: 12px 0;
    font-size: pxToRem(26);
    line-height: 120%;
    font-weight: 300;
}

.list-item__text, .filter-item__text {
    display: block;
    margin-right: initial;
    font-size: pxToRem(16);
    color: #333C40;
}

.facts {
    display: flex;
    flex-wrap: wrap;
    margin-top: auto;
    font-size: pxToRem(16);
    color: $font-color-dark;

    li:not(:last-child) {
        margin-right: $spacing-s;
    }

    li::before {
        content: '+';
        margin-right: 5px;
    }
}

.list-item__container--links, .filter-item__container--links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: 100%;
}

.list-item__price, .filter-item__price {
    color: $font-color-dark;
}

.list-item__links, .filter-item__links {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: $spacing-xs;
    min-width: 218px;

    .button:not(.button--link).link__booking {
        background-color: $color-primary;
    }
}

.list-item__links .link__website, .filter-item__links .link__website {
    margin-top: auto;
    margin-bottom: $spacing-xs;
}

.list-item__links .link__booking::before, .filter-item__links .link__booking::before {
    transform: rotate(0deg);
}

.list-item__links .button.button--large.button--booking:not(.button--link).link__booking, .filter-item__links .button.button--large.button--booking:not(.button--link).link__booking {
    width: 100%;
}

.list-item__links--single {
    justify-content: flex-end;
}

.list__item:hover .list__readmore {
    background: $color-primary-hover;
    transition: all .3s ease;
}

.list__item:hover .button.button--large.button--booking.list__readmore:not(.button--link)::before {
    opacity: 1;
}

.list__item:hover .button.button--large.button--booking.list__readmore:not(.button--link)::after {
    flex: auto;
    transform: none;
}

.list-item__links .button.button--large.button--booking:not(.button--link).list__readmore {
    width: 100%;
}

@include media-breakpoint-down(lg) {

    .list__item, .filter__item {
        flex-wrap: wrap;
    }

    .list-item__container, .filter-item__container {
        flex-direction: column;
    }

    .list-item__content .facts, .filter-item__content .facts {
        margin-top: $spacing-mobile-s;
    }

    .list, .filter {
        padding: $spacing-s 0 $spacing-xl;
        margin: $spacing-mobile-m 0;
    }

    .list__body, .filter-item__body {
        flex-direction: column;
        padding: $spacing-xs $spacing-xs 0;
    }

    .list-item__content, .filter-item__content {
        padding-right: initial;
        margin-right: initial;
        border-right: none;
    }

    .list-item__container--links, .filter-item__container--links {
        flex-direction: row-reverse;
        justify-content: space-between;
        width: 100%;
        margin: $spacing-s 0 $spacing-xs;
    }

    .list-item__links .link__website, .filter-item__links .link__website {
        margin: initial;
    }

    .list-item__links, .filter-item__links {
        align-items: flex-start;
        padding: 0 $spacing-xs $spacing-xs;
    }

    .list-item__links .button.button--large.button--booking:not(.button--link).list__readmore {
        margin-top: 1rem;
    }
}
