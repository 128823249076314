@import "../icon/sass-inline-svg";

/**
 * Default
 */

[data-page] [data-id]:not(.visible) {
    display: none;
}

.teaser {
    margin: $spacing-xxl 0;
}

.teaser__item {
    position: relative;
    display: block;
    transition: all .3s ease;
}

.teaser__image {
    position: relative;
}

.teaser__headline.headline {
    position: absolute;
    bottom: $spacing-l;
    z-index: 10;
    padding: 12px 18px 14px 18px;
    color: $font-color-dark;
    background-color: $color-white;
    transition: all .3s ease;
}

.teaser__body {
    display: flex;
    justify-content: space-between;
    padding: 14px $spacing-xs $spacing-xs;
    color: $font-color-dark;
    background-color: $color-tertiary-light;
    transition: all .3s ease;
}

.teasertext {
    margin-right: 18px;
    flex: 1 1 auto;
}

.teasertext {
    display: -webkit-box;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    /*! autoprefixer: on */
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.teaser__price {
    flex: 1 0 auto;
}

.teaser__item:hover {
    box-shadow: 0 2px 36px rgba($color-black, .12);
    .teaser__headline.headline {
        padding-left: $spacing-m;
        background-color: $color-primary-hover;
        color: $color-white;
    }
    .teaser__body {
        background-color: $color-white;
    }
}

/* || Teaser Big */

.teaser-big__headline.headline {
    left: 50%;
    bottom: 50%;
    transform: translate(-50%, 50%);
    padding: 12px $spacing-m 14px;
}

@include media-breakpoint-down(lg) {
    .teaser-item:not(:last-child) {
        margin-bottom: $spacing-m;
    }
}

@include media-breakpoint-down(md) {
    .teaser-item:not(:last-child) {
        margin-bottom: $spacing-l;
    }
}

@include media-breakpoint-down(sm) {
    .teaser {
        margin: $spacing-mobile-m 0;
    }

    .teaser__headline.headline {
        font-size: pxToRem(16);
    }

    .teaser-big__headline.headline {
        bottom: $spacing-m;
        transform: translateX(-50%);
    }
}