.popup {
    text-align: center;

    &__close {
        top: pxToRem(-12);
        right: pxToRem(-12);
        width: pxToRem(36);
        height: pxToRem(36);
        background-color: $color-primary;
        background-image: inline-svg('close', $color-white);
        background-position: center;
        background-size: pxToRem(28);
        background-repeat: no-repeat;
        border-radius: 50%;
    }

    &__body {
        padding: pxToRem(48) pxToRem(28);
    }

    .popup__headline {
        font-size: pxToRem(28);
        margin-bottom: pxToRem(28);

        span {
            display: block;
        }
    }

    &__forwarding {
        padding: pxToRem(36) pxToRem(20);
        background-color: $color-tertiary;

        p {
            font-size: pxToRem(20);
        }
    }

    &__notice {
        margin-top: pxToRem(28);
    }
}

@include media-breakpoint-down(sm) {
    .popup {
        padding: initial;

        &__close {
            top: pxToRem(8);
            right: pxToRem(8);
        }

        &__body {
            min-height: 100%;
        }

        .popup__headline {
            span {
                display: inline;
            }
        }
    }
}