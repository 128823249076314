.js-nav-open {
    overflow: hidden;
}

.header__navigation {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    margin-left: auto;
}

.header__navigation > ul {
    //width: calc(100% + 16px);
    //margin: 0 -8px;
    //margin-top: 36px;
    margin-right: auto;
    ul {

        @media(min-width: 1300px) {
            opacity: 0;
            pointer-events: none;
            display: block;
            position: absolute;
            top: 3.5rem;
            transition: opacity .4s ease;
            z-index: 100;
            &:before {
                content: '';
                position: absolute;
                height: 20px;
                width: 100%;
                top: -17px;
            }
        }
    }
}

.header__navigation > ul > li {
    display: inline-block;
    padding: 0 8px;
    position: relative;

    &:not(:last-child) {
        margin-right: pxToRem(32);
    }

    & > a::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(calc(-50%));
        display: block;
        height: 2px;
        width: calc(100% - 16px);
        background-color: $color-primary;
        opacity: 0;
        transition: all .4s ease;
    }

    &:hover > a::after {
        opacity: 1;
    }

    &:hover ul {
        opacity: 1;
        pointer-events: auto;
    }

    a {
        display: block;
        padding: 8px;
        color: $font-color-dark;
        transition: all .3s ease;
    }

    &:hover > a {
        transform: translateY(-8px);
    }
}

.header__navigation ul ul li {
    display: block;
    padding: 0 12px;

    &:first-child {
        margin-top: 15px;
    }

    &:last-child {
        margin-bottom: 17px;
    }

    a {
        position: relative;
        display: block;
        padding: 5px 12px 5px 24px;
        font-size: pxToRem(16);

        &::before {
            content: '';
            position: absolute;
            top: 10px;
            left: 1px;
            width: 10px;
            height: 10px;
            background-image: inline-svg('arrow', $color-primary);
            background-size: 10px 10px;
            background-position: center center;
            background-repeat: no-repeat;
            transition: all .3s ease;
        }

        &:hover {
            text-decoration: underline;
        }

        &:hover::before {
            top: 8px;
            background-image: inline-svg('arrow-long', $color-secondary);
            width: 14px;
            height: 14px;
            background-size: 14px 14px;
        }
    }
}

.header__navigation .level-0 ul {
    left: 50%;
    transform: translateX(-50%);
    min-width: 200px;
    width: auto;
    background-color: $color-white;
    box-shadow: 0 2px 36px rgba($color-black, .12);

    &::after {
        content: "";
        position: absolute;
        top: -8px;
        left: 50%;
        transform: translateX(-50%);
        border-style: solid;
        border-width: 0 9px 8px 9px;
        border-color: transparent transparent $color-white transparent;
    }
}

/* || Burger */

.burger {
    display: none;
    transition: all 1s ease;
}

.burger .hamburger {
    padding: 8px 3px;
}

.burger .hamburger-box {
    width: 33px;
}

.burger .hamburger-inner {
    width: 27px;
    height: 2px;

    &::before {
        top: -8px;
        width: 33px;
        height: 2px;
    }

    &::after {
        top: 8px;
        width: 33px;
        height: 2px;
    }
}

.burger .hamburger--spin.is-active .hamburger-inner::before {
    opacity: 1;
}

.burger .hamburger-inner {
    background-color: $color-black;

    &::before {
        background-color: $color-black;
    }

    &::after {
        background-color: $color-black;
    }
}

.js-nav-open .burger .hamburger-inner {
    background-color: $color-white;

    &::before {
        background-color: $color-white;
    }

    &::after {
        top: 0;
        background-color: $color-white;
    }
}

.plus {
    display: none;
    width: 60px;
    height: 60px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 100;
    transition: transform .4s ease
}

.plus:before {
    transition: all .6s ease;
    position: absolute;
    left: 0;
    top: 0;
    display: block;
    content: '';
    width: 60px;
    height: 60px;
    background-image: inline-svg('plus', $color-white, $color-white);
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: 20px 20px;
    background-size: 20px 20px;
}

.js-nav-scrolled02 {
    .header__navigation > ul {
        margin-top: initial;
    }

    .header__navigation .level-0 > a {
        color: $font-color-light;
    }
}

.header__contact--mobile {
    display: none;
}

@media(max-width: 1550px) {
    .js-nav-scrolled02 .header__navigation .level-0:not(:last-child) {
        margin-right: $spacing-m;
    }
}

@media(max-width: 1300px) {
    .burger {
        display: block;
        position: absolute;
        left: $spacing-m;
        top: 50%;
        transform: translateY(-50%);
        z-index: 600;
    }

    .plus {
        display: block;
    }
    .js-subnav-open .plus {
        transform: rotate(-45deg);
    }
    .js-nav-open {
        .header__navigation {
            left: 0;
        }
    }
    .header__navigation {
        position: fixed;
        z-index: 550;
        left: -100vw;
        top: 0;
        width: 100%;
        height: 100%;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        background: $color-secondary;
        -ms-overflow-style: scrollbar;
        overflow-style: scrollbar;
        align-items: center;
        padding: 18px 14px 100px;
        margin-top: initial;
        transition: all .6s ease;
        overflow-y: auto;

        & > ul {
            display: inline-block;
            width: 100%;
            margin-top: $spacing-l;

            li {
                display: block;
                text-align: initial;
                font-size: pxToRem(30);
                line-height: 120%;
                padding: 15px 0;
                margin-left: initial !important;

                a {
                    position: relative;
                    display: block;
                    width: 100%;
                    color: $font-color-light;
                }
            }

            & > li > a::after {
                content: none;
            }

            & > li:hover a {
                transform: none;
            }
        }

        .level-0:not(:last-child) {
            margin-right: initial;
        }

        .level-0 ul {
            left: 0;
            transform: none;
            background-color: transparent;
            box-shadow: none;

            &::after {
                content: none;
            }

            & > li a {
                padding-bottom: $spacing-xxs;
            }

            & > li a:after {
                top: calc(50% - 3px);
                width: 11px;
                height: 11px;
                background-image: inline-svg('arrow', $color-primary);
                background-size: 11px 11px;
                border: none;
            }
        }

        ul ul {
            display: none;
            margin-top: initial;

            li {
                font-size: pxToRem(20);
                padding: 15px 0 0;

                &:first-child {
                    margin-top: initial;
                }

                &:last-child {
                    margin-bottom: initial;
                }

                a {
                    padding-left: 26px;
                    font-size: pxToRem(16);
                    color: $font-color-light;
                    border-bottom: 1px solid $font-color-light;

                    &::before {
                        width: 12px;
                        height: 12px;
                        background-image: inline-svg('arrow', $color-primary);
                        background-size: 12px 12px;
                    }
                }
            }
        }

        .level-0::before, .level-0::after {
            content: none;
        }

        .level-0 > a {
            padding-bottom: 10px;
            font-size: pxToRem(22) !important;
            font-weight: 700;
            border-bottom: 2px solid $color-white;
        }
    }

    .header__navigation--mobile {
        display: flex;
        width: 100%;
        flex-direction: column;
        margin-top: $spacing-xs;
        font-weight: 300;
        color: $font-color-light;

        span {
            display: inline-block;
            margin-bottom: 2px;
            font-weight: 500;
        }

        .header__socials {
            display: flex;
            padding-bottom: 120px;
            margin: 20px 0;

            a {
                width: 50px;
                height: 50px;

                &:not(:first-child) {
                    margin-left: 16px;
                }
            }
        }
    }

    .header__contact--mobile {
        display: flex;
        width: 100%;
        padding-bottom: 130px;
        margin-top: $spacing-xl;

        hr {
            margin: 12px 0;
            background-color: $color-primary;
        }

        p, a {
            color: $font-color-light;
            font-weight: 700;
        }

        .contact__phone, .contact__email {
            font-size: pxToRem(15);

            a {
                margin-left: $spacing-xs;
                font-weight: 400;
            }
        }
    }

    .js-nav-scrolled02 {
        .burger {
            //top: 22px;
            .hamburger-inner {
                background-color: $color-white;
                &::before, &::after {
                    background-color: $color-white;
                }
            }
        }
        .header__navigation .level-0:not(:last-child) {
            margin-right: initial;
        }

        .header__navigation > ul {
            margin-top: $spacing-m;
        }
    }
}
