.faq.list {
    //padding-bottom: 4rem;
    padding-top: calc(3rem + 8px);
    margin-bottom: initial;
}

.faq__item {
    //border-bottom: 1px solid #000;
    margin-bottom: 8px;
    background: $color-white;
}
.faq__item .faq__header {
    cursor: pointer;
    position: relative;
    padding: 10px 8px 10px 16px;
    padding-right: 44px;
    h3 {
        font-size: pxToRem(26);
        font-weight: 300;
        color: $font-color-dark;
    }
}

.faq__item .faq__header::before {
    content: '';
    position: absolute;
    top: calc(50% - 5px);
    right: 31px;
    width: 10px;
    height: 10px;
    background-image: inline-svg('plus', $color-black);
    transition: all .2s ease;
}

.faq__item .faq__header:after {
    content: '';
    position: absolute;
    top: calc(50% - 18px);
    right: 18px;
    display: block;
    width: 36px;
    height: 36px;
    border: 1px solid $color-black;
    border-radius: 50%;
}

.faq .uk-open .faq__item .faq__header:before {
    background-image: inline-svg('minus', $color-black);
}

.faq__item .faq__content {
    padding: $spacing-xs $spacing-xs $spacing-s $spacing-xl ;
    //background-color: rgba($color-white, .7);
}

.faq__item .article__content > :last-child {
    margin-bottom: 0;
}

.faq__item .faq__body {
    z-index: 5;
    position: relative;

    p:first-child {
        margin-top: initial;
    }
}

.faq__item .faq__image {
    z-index: 10;
    position: relative;
}

.faq-image__container {
    display: flex;
}

.faq__image {
    display: flex;
    flex-direction: column;
    margin-top: $spacing-m;

    &:not(:first-child) {
        margin-left: $spacing-m;
    }

    &:nth-child(n + 1) {
        //flex-basis: calc(66% - #{$spacing-m});
        flex: 1 0 66%;
    }

    &:nth-child(n + 2) {
        flex-basis: calc(33% - #{$spacing-m});
    }
}