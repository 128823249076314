@import "@src/scss/icon/sass-inline-svg";

.directions .directions__map {
    position: relative;
    height: 65vh;
    min-height: 400px;
    overflow: hidden;
    background: $color-gray-10;
}

.directions .directions__map:before {
    content: '\00a0';
    z-index: 1;
    position: absolute;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    display: block;
    width: 30px;
    height: 30px;
    border: 2px solid $color-gray-30;
    border-top-color: transparent;
    border-radius: 500px;
    animation: loadingSpinner 0.85s linear infinite;
}

.directions .directions__map > * {
    z-index: 10;
    position: absolute;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 100%;
}

.directions .directions__form {
    padding: 30px 0;
    margin: 0 auto;
    max-width: 480px;
}

.directions .directions__form h3 {
    text-align: center;
}

.directions .directions__error {
    display: none;
    font-size: $base-font-size;
    color: $color-danger;
}

.directions .directions__input,
.directions .directions__submit {
    width: 100%;
}

.directions .directions__panel {
    padding-bottom: 30px;
}

.directions .directions__legend {
    overflow-y: auto;
}

.directions-legend .directions-legend__title {
    border-bottom: 1px solid $color-gray-10;
}

.directions-legend__reset:not(:first-child),
.directions-legend:not(:first-child) .directions-legend__title {
    border-top: 1px solid $color-gray-10;
    margin-top: -1px;
}

.directions-legend__reset a,
.directions-legend .directions-legend__title a {
    display: block;
    padding: 15px 25px;
}

.directions-legend .directions-legend__title a {
    cursor: pointer;
    position: relative;
    padding-right: 51px;
}

.directions-legend .directions-legend__title a:after {
    content: '\00a0';
    position: absolute;
    top: calc(50% - 7px);
    right: 20px;
    display: block;
    width: 16px;
    height: 16px;
    background-size: 14px 14px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: inline-svg('angle-down', $color-black);
    transition: transform 0.3s;
}

.directions-legend.uk-open .directions-legend__title a:after {
    transform: rotate(180deg);
}

.directions-legend__reset a {
    text-align: center;
}

.directions-legend .directions-legend__items {
    padding: 6px 0;
}

.directions-legend .directions-legend__item a {
    display: block;
    padding: 6px 25px;
    color: $color-gray-70;
}

.directions-legend .directions-legend__item a.active {
    color: $color-primary;
}
