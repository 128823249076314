

$button-font-size: $base-font-size !default;
$button-height: ($button-font-size*2.5) !default;
$button-ratio: 0.3 !default;

.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 0;
    white-space: nowrap;
}

.button.button--block {
    width: 100%;
}

.button:not(.button--link) {
    position: relative;
    height: 36px;
    padding: 7px 16px 8px 16px;
    font-size: pxToRem(14);
    color: $font-color-light;
    background-color: $color-primary;
    transition: all .3s ease;

    span {
        transition: all .3s ease;
    }

    &::after {
        content: '';
        position: relative;
        width: 10px;
        height: 10px;
        margin-left: 10px;
        background-image: inline-svg('arrow', $color-white);
        background-size: 10px 10px;
        background-position: center center;
        background-repeat: no-repeat;
        transition: all .3s ease;
    }
}

.button:not(.button--link):hover,
.button:not(.button--link):focus {
    background: $color-primary-hover;

    span {
        transform: translateX(-4px);
    }

    &::after {
        background-image: inline-svg('arrow', $color-secondary);
        transform: translateX(4px);
    }
}

.button[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
}

.button.button--square:not(.button--link) {
    padding: initial;
    background: none;
    color: $font-color-dark;

    &::after {
        width: 36px;
        height: 36px;
        margin-left: 16px;
        background-color: $color-primary;
        transform: none;
    }
}

.button.button--square:not(.button--link):hover,
.button.button--square:not(.button--link):focus {
    &::after {
        background-color: $color-primary-hover;
    }
}

.button.button--small:not(.button--link) {
    height: 22px;
    padding: 0 8px 1px 8px;
    font-size: pxToRem(14);
}

.button.button--small:not(.button--link):hover,
.button.button--small:not(.button--link):focus {
    span {
        transform: none;
    }

    &::after {
        transform: none;
    }
}

.button.button--small.button--square:not(.button--link) {
    padding: initial;
    &::after {
        width: 22px;
        height: 22px;
        margin-left: $spacing-xxs;
    }
}

.button.button--large:not(.button--link) {
    height: 50px;
    padding: 12px 25px 14px 24px;
    font-size: pxToRem(16);

    span {
        position: relative;
        transition: all .3s ease;
    }
}

.button.button--large:not(.button--link):hover,
.button.button--large:not(.button--link):focus {
    background: $color-primary-hover;

    span {
        transform: translateX(-8px);
    }

    &::after {
        background-image: inline-svg('arrow', $color-secondary);
        transform: translateX(8px);
    }
}

.button.button--large.button--square:not(.button--link) {
    padding: initial;
    font-size: pxToRem(16);
    background: none;
    &::after {
        width: 50px;
        height: 50px;
        margin-left: $spacing-xs;
        transform: none;
    }
}

.button.button--large.button--booking:not(.button--link) {
    width: 220px;
    height: 50px;
    span {
        flex: 1;
        max-width: fit-content;
    }
    &::after {
        flex: 0;
        background-image: none;
        transition: flex .5s;
    }

    &::before {
        content: '';
        position: absolute;
        right: 17px;
        width: 10px;
        height: 10px;
        background-image: inline-svg('arrow', $color-white);
        background-size: 10px 10px;
        background-position: center center;
        opacity: 0;
        transition: opacity .3s ease;
    }
}

.button.button--large.button--booking:not(.button--link):hover,
.button.button--large.button--booking:not(.button--link):focus {
    transition: all .3s ease;

    span {
        transform: none;
    }

    &::after {
        flex: auto;
        transform: none;
    }
    &::before {
        opacity: 1;
    }
}

/**
 * Link
 */

.button.button--link {
    font-size: $button-font-size;
    color: $color-primary;
    transition: 0.15s color;
}

.button.button--link:hover,
.button.button--link:focus {
    color: $color-primary-hover;
}

.button.button--link.button--primary {
    color: $color-primary;
}

.button.button--link.button--primary:hover,
.button.button--link.button--primary:focus {
    color: $color-primary-hover;
}

.button.button--link.button--secondary {
    color: $color-secondary;
}

.button.button--link.button--secondary:hover,
.button.button--link.button--secondary:focus {
    color: $color-secondary-hover;
}


.button.button--link.button--success {
    color: $color-success;
}

.button.button--link.button--success:hover,
.button.button--link.button--success:focus {
    color: $color-success-darker;
}

.button.button--link.button--warning {
    color: $color-warning;
}

.button.button--link.button--warning:hover,
.button.button--link.button--warning:focus {
    color: $color-warning-darker;
}

.button.button--link.button--danger {
    color: $color-danger;
}

.button.button--link.button--danger:hover,
.button.button--link.button--danger:focus {
    color: $color-danger-darker;
}

.button.button--link.button--info {
    color: $color-info;
}

.button.button--link.button--info:hover,
.button.button--link.button--info:focus {
    color: $color-info-darker;
}

.button.button--small.button--link {
    font-size: $button-font-size * 0.875;
}

.button.button--large.button--link {
    font-size: $button-font-size * 1.125;
}

.button.button--link[disabled],
.button.button--link[disabled]:hover,
.button.button--link[disabled]:focus {
    color: $color-gray-40;
}

/**
 * Button primary
 */

//.button.button--primary:not(.button--link) {
//    @include dynamic-color($color-primary);
//    border-color: $color-primary;
//}
//
//.button.button--primary:not(.button--link):hover,
//.button.button--primary:not(.button--link):focus {
//    @include dynamic-color($color-primary-hover);
//    border-color: $color-primary-hover;
//}
//
//.button.button--primary:not(.button--link):active {
//    @include dynamic-color($color-primary-dark);
//    border-color: $color-primary-dark;
//}
//
//.button.button--primary:not(.button--link)[disabled],
//.button.button--primary:not(.button--link)[disabled]:hover,
//.button.button--primary:not(.button--link)[disabled]:focus {
//    @include dynamic-color($color-primary);
//    border-color: $color-primary;
//}
//
///**
// * Button secondary
// */
//
//.button.button--secondary:not(.button--link) {
//    @include dynamic-color($color-secondary);
//    border-color: $color-secondary;
//}
//
//.button.button--secondary:not(.button--link):hover,
//.button.button--secondary:not(.button--link):focus {
//    @include dynamic-color($color-secondary-hover);
//    border-color: $color-secondary-hover;
//}
//
//.button.button--secondary:not(.button--link):active {
//    @include dynamic-color($color-secondary-dark);
//    border-color: $color-secondary-dark;
//}
//
//.button.button--secondary:not(.button--link)[disabled],
//.button.button--secondary:not(.button--link)[disabled]:hover,
//.button.button--secondary:not(.button--link)[disabled]:focus {
//    @include dynamic-color($color-secondary);
//    border-color: $color-secondary;
//}

/**
 * Button success
 */

.button.button--success:not(.button--link) {
    @include dynamic-color($color-success);
    border-color: $color-success;
}

.button.button--success:not(.button--link):hover,
.button.button--success:not(.button--link):focus {
    @include dynamic-color($color-success-light);
    border-color: $color-success-light;
}

.button.button--success:not(.button--link):active {
    @include dynamic-color($color-success-dark);
    border-color: $color-success-dark;
}

.button.button--success:not(.button--link)[disabled],
.button.button--success:not(.button--link)[disabled]:hover,
.button.button--success:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-success-lighter);
    border-color: $color-success-lighter;
}

/**
 * Button warning
 */

.button.button--warning:not(.button--link) {
    @include dynamic-color($color-warning);
    border-color: $color-warning;
}

.button.button--warning:not(.button--link):hover,
.button.button--warning:not(.button--link):focus {
    @include dynamic-color($color-warning-light);
    border-color: $color-warning-light;
}

.button.button--warning:not(.button--link):active {
    @include dynamic-color($color-warning-dark);
    border-color: $color-warning-dark;
}

.button.button--warning:not(.button--link)[disabled],
.button.button--warning:not(.button--link)[disabled]:hover,
.button.button--warning:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-warning-lighter);
    border-color: $color-warning-lighter;
}

/**
 * Button danger
 */

.button.button--danger:not(.button--link) {
    @include dynamic-color($color-danger);
    border-color: $color-danger;
}

.button.button--danger:not(.button--link):hover,
.button.button--danger:not(.button--link):focus {
    @include dynamic-color($color-danger-light);
    border-color: $color-danger-light;
}

.button.button--danger:not(.button--link):active {
    @include dynamic-color($color-danger-dark);
    border-color: $color-danger-dark;
}

.button.button--danger:not(.button--link)[disabled],
.button.button--danger:not(.button--link)[disabled]:hover,
.button.button--danger:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-danger-lighter);
    border-color: $color-danger-lighter;
}

/**
 * Button info
 */

.button.button--info:not(.button--link) {
    @include dynamic-color($color-info);
    border-color: $color-info;
}

.button.button--info:not(.button--link):hover,
.button.button--info:not(.button--link):focus {
    @include dynamic-color($color-info-light);
    border-color: $color-info-light;
}

.button.button--info:not(.button--link):active {
    @include dynamic-color($color-info-dark);
    border-color: $color-info-dark;
}

.button.button--info:not(.button--link)[disabled],
.button.button--info:not(.button--link)[disabled]:hover,
.button.button--info:not(.button--link)[disabled]:focus {
    @include dynamic-color($color-info-lighter);
    border-color: $color-info-lighter;
}

/**
 * Button group
 */

.button-group {
    display: flex;
    flex-direction: row;
}

.button-group > .button,
.button-group > .input {
    position: relative;
    border-radius: 0;
    margin-left: -1px;
}

.button-group > .input {
    flex: 1 1 auto;
}

.button-group > .button {
    flex: 0 0 auto;
}

.button-group > .button:hover,
.button-group > .input:hover {
    z-index: 10;
}

.button-group > .button:first-child,
.button-group > .input:first-child {
    border-radius: $border-radius 0 0 $border-radius;
    margin-left: 0;
}

.button-group > .button:last-child,
.button-group > .input:last-child {
    border-radius: 0 $border-radius $border-radius 0;
}

.formular-item .button.button--square {
    width: pxToRem(48);
    color: $color-white;
    background: transparent;

    &::after {
        content: none;
    }
}