.gallery {
    margin: $spacing-xxl 0;
}

.gallery-item__image--container {
    display: flex;
    flex-direction: column;
}

.gallery__item {
    display: block;
    margin: 24px 0;
}

.image__description {
    margin-top: 12px;
    font-size: pxToRem(16);
    font-weight: 700;
    color: $font-color-dark;
}

.item-galerie {
    &:nth-child(n+7) {
        max-height: 0;
        overflow: hidden;
        transition: all .8s ease;
        transition-delay: .2s;
    }
}

.js-show-more {
    .item-galerie {
        &:nth-child(n+7) {
            max-height: 900px;
        }
    }

    + .items-galerie__more-button {
        display: none;
    }
}

.items-galerie__more-button {
    display: flex;
    justify-content: center;
    margin-top: 12px;
}

.js-btn-show-more-galerie {
    padding-bottom: 46px;
    font-size: pxToRem(16);
    color: $font-color-dark;
    transition: all .3s ease;
    &::after {
        content: '';
        position: absolute;
        bottom: 4px;
        left: 50%;
        width: 36px;
        height: 36px;
        background-color: $color-primary;
        background-image: inline-svg('arrow', $color-white);
        background-position: center center;
        background-size: 10px 10px;
        background-repeat: no-repeat;
        border-radius: 50%;
        transform: translateX(-50%) rotate(90deg) !important;
        transition: all .3s ease;
    }

    &:hover {
        cursor: pointer;
    }

    &:hover::after {
        bottom: 0;
        background-color: $color-primary-hover;
        background-image: inline-svg('arrow', $color-secondary);
    }
}