.filter__container {
    padding: $spacing-l 0 $spacing-xxl;
    margin: $spacing-xxl 0;
    background-color: $color-primary-dark;

    .list {
        padding: initial;
        margin: initial;
        margin-top: $spacing-l;
        background: none;
    }
}

.filter__label {
    margin-bottom: 20px;
    font-weight: 700;
    color: $font-color-light;
}

.filter-select .chosen-pseudo {
    padding: 0 4px;
    padding-right: 18px;
    font-size: pxToRem(16);
    color: $font-color-light;
    background-color: transparent;
    background-image: inline-svg('arrow-down', $color-white);
    background-position: calc(100% - 4px) 50%;
    border: none;
    border-bottom: 1px solid $color-white;

    &:hover, &:focus {
        border: none;
        border-bottom: 1px solid $color-primary;
    }
}

.filter-select {
    position: relative;

    &.open .filter-select__list {
        display: inline-flex;
    }
}

.filter-select__list {
    position: absolute;
    z-index: 100;
    display: none;
    min-width: 250px;
    width: auto;
    margin-top: $spacing-xs;
    background-color: $color-white;

    &::before {
        content: "";
        position: absolute;
        top: -8px;
        left: 20%;
        border-style: solid;
        border-width: 0 9px 8px 9px;
        border-color: transparent transparent $color-white transparent;
    }
}

.filter-select__list ul {
    width: 100%;
    padding: $spacing-xs $spacing-xs $spacing-xs 12px;

    li {
        display: flex;
        align-items: center;

        input[type=checkbox] {
            appearance: none;
            width: 18px;
            height: 18px;
            border: 1px solid $color-black;
            border-radius: 50%;

            &:checked {
                background-image: inline-svg('circle-checked', $color-black, transparent);
                background-position: center center;
                background-size: 20px 20px;
                border: none;
            }
        }

        label {
            flex: 1;
            margin-left: 10px;
        }

        &:hover {
            cursor: pointer;
        }
    }

    li:not(:last-child) {
        margin-bottom: 10px;
    }
}

.filter__category:not(:last-child) {
    padding-right: $spacing-mobile-s;
}

.filter__container select, .filter__container input {
    appearance: none;
    padding-bottom: $spacing-mobile-s;
    padding-right: 5px;
    font-size: pxToRem(16);
    color: $font-color-light;
    background-color: transparent;
    background-image: inline-svg('arrow-down', $color-white);
    background-position: right 5px bottom 19px;
    background-size: 10px 10px;
    background-repeat: no-repeat;
    border: none;
    border-bottom: 1px solid $color-white;
}

.filter-category__container {
    margin-bottom: $spacing-l;
}

.filter__category {
    display: flex;
    flex-direction: column;
}

.filter-item__links .button.button--large.button--booking:not(.button--link).link__booking.filter-item__button--off {
    color: $font-color;
    background-color: transparent;
    border: 1px solid $color-primary-light;
    &::after, &::before, &:hover::before, &:hover::after {
        content: none;
    }
}

.filter-item__links .button.button--small.button--square:not(.button--link).link__alternative {
    margin-top: $spacing-xs;
}

.counter__outer {
    display: flex;
    justify-content: space-between;
    padding-bottom: 9px;
    color: $font-color-light;
    border-bottom: 1px solid $color-white;
}

@include media-breakpoint-down(lg) {
    .filter__category:not(:last-child) {
        padding-right: initial;
        margin-bottom: $spacing-s;
    }

    .filter__label {
        margin-bottom: 5px;
    }

    .filter-item__links .button.button--small.button--square:not(.button--link).link__alternative {
        align-self: end;
    }
}
