/*
  * Use only local fonts!!
  * Preload only woff2 in src/Templates/element/html/head.php
  * Preloader Example: <link rel="preload" href="/src/theme/default/font/***.woff2" as="font" type="font/woff2" crossorigin>
  * IMPORTANT!!!! All webfonts must be converted with https://transfonter.org/ and the option "Fix vertical metrics"
*/

@font-face {
    //https://web.dev/font-display/?utm_source=lighthouse&utm_medium=devtools
    font-display: swap; //avoid showing invisible text
    //font-family: "???";
    //src: url("/src/theme/default/font/???");

}

@font-face {
    font-family: 'Montserrat';
    src: url('/dist/font/Montserrat-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/dist/font/Montserrat-Light.woff2') format('woff2');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/dist/font/Montserrat-Bold.woff2') format('woff2');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}