/**
 * Slider
 */

.slider, .slider-big {
    position: relative;
    //padding-bottom: $spacing-m;
    margin-top: 138px;
    margin-bottom: $spacing-mobile-m;
    overflow: hidden;
}

.slider__navigation {
    width: 100%;
    height: 0;
}

.slider__prev,
.slider__next {
    position: absolute;
    top: calc(50% - 25px);
    display: block;
    width: 50px;
    height: 50px;
    background-color: rgba($color-black, 0.3);
    background-size: 16px 16px;
    background-position: center center;
    background-repeat: no-repeat;
}

.slider__prev {
    left: 0;
    background-image: inline-svg('angle-left', $color-white);
}

.slider__next {
    right: 0;
    background-image: inline-svg('angle-right', $color-white);
}

.teaser--slider .teaser__item {
    position: relative;
    height: 100%;
    display: block;
    width: 100%;
}

.slider-big__item {
    padding-bottom: 36px;
}

.slider-big__item .price {
    white-space: nowrap;
}

.swiper-slide {
    flex-shrink: 0;
    padding-left: 32px !important;
    padding-right: 32px !important;
}

.swiper-button-prev, .swiper-button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 68px;
    height: 68px;
    background-color: rgba($color-black, .10);
    background-image: inline-svg('arrow-long-thin', $color-white);
    background-position: center center;
    background-size: 46px 46px;
    background-repeat: no-repeat;
    border-radius: 50%;
    transition: all .3s ease;
    &:after {
        display: none;
    }

    &:hover {
        background-color: $color-secondary-hover;
        background-image: inline-svg('arrow-long-thin', $color-secondary);
    }
}

.swiper-button-prev {
    left: $spacing-m;
    transform: translateY(-50%) rotate(180deg);
}

.swiper-button-next {
    right: $spacing-m;
}

.teaser-slider {
    padding: $spacing-l 0 $spacing-xxl;
    background-color: $color-background;

    .container {
        position: initial;
    }
}

@media(max-width: 1300px) {
    .slider, .slider-big {
        margin-top: 110px;
    }
}

@include media-breakpoint-down(md) {
    .slider-big .container {
        padding-left: 0 !important;
        padding-right: 0 !important;

        .grid.grid--gutter {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }
    }

    .slider-big .swiper-slide {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .slider .swiper-slide {
        //padding-left: 0 !important;
        padding: 0 14px !important;
    }
}

@include media-breakpoint-down(sm) {
    .swiper-button-prev, .swiper-button-next {
        width: 36px;
        height: 36px;
        background-size: 24px 24px;
    }

    .swiper-button-prev {
        left: $spacing-xxs;
    }

    .swiper-button-next {
        right: $spacing-xxs;
    }

    .slider, .slider-big {
        margin-top: 80px;
    }

    .slider .container {
        padding-left: 0 !important;
    }

    .swiper-wrapper {
        margin-left: -14px !important;
    }

    .slider .swiper-slide {
        padding-left: 7px !important;
    }

    .slider {
        .swiper-button-prev, .swiper-button-next {
            top: 40%;
            transform: translateY(0);
        }

        .swiper-button-prev {
            transform: rotate(180deg);
        }
    }
}

.splide__arrows {
    transition: all .3s ease;
}

.splide__arrow {
    width: pxToRem(72);
    height: pxToRem(72);
    background-color: rgba($color-black, 0.2);
    background-position: center;
    background-size: 48px;
    background-repeat: no-repeat;
    transition: all .3s ease;
    opacity: 1;

    &--prev {
        left: pxToRem(36);
        background-image: inline-svg('arrow-lg-left', $color-white);
    }

    &--next {
        right: pxToRem(36);
        background-image: inline-svg('arrow-lg-right', $color-white);
    }

    &:hover, &:focus {
        background-color: $color-primary;
        opacity: 1 !important;
    }
}

@include media-breakpoint-down(md) {
    .splide__arrow {
        top: 30%;
        width: pxToRem(36);
        height: pxToRem(36);
        background-color: rgba($color-black, 0.2);
        background-position: center;
        background-size: 28px;
        background-repeat: no-repeat;
        transition: all .3s ease;
        opacity: 1;

        &--prev {
            left: pxToRem(8);
            background-image: inline-svg('arrow-lg-left', $color-white);
        }

        &--next {
            right: pxToRem(8);
            background-image: inline-svg('arrow-lg-right', $color-white);
        }
    }
}