

.keyvisual {
    min-height: 200px;
    position: relative;
    height: auto; /*exact img size*/
    margin-top: $header-height;
}

.keyvisual-empty {
    height: calc(#{$header-height} + #{pxToRem(48)}) !important;
}

.keyvisual .image {
    position: unset;
    height: 100%;
}

.keyvisual .swiper {
    overflow: hidden !important;
    height: 100%;
}

.keyvisual__slide.swiper-slide-next .image, .keyvisual__slide.swiper-slide-prev .image, .keyvisual__slide.swiper-slide-active .image {
    visibility: visible;
}

.keyvisual__slide .image{
    visibility: hidden;

}
body[class*="view-frontpage"] .keyvisual,
.keyvisual__big {
   // height: calc(100vh - 80px /*HeaderHeight*/);
}

@media(min-width: 1300px) {

    .keyvisual {
        //height: calc(70vh - 80px /*HeaderHeight*/);
        //height: calc(100vh - 138px - 40px);
        max-height: 100vh;

        .keyvisual__slider, .keyvisual__wrapper, .keyvisual__wrapper > ul, .keyvisual__slide, .image__outer, .image {
            height: 100%;
            max-height: calc(100vh - #{$header-height});
        }
    }

    body[class*="view-frontpage"] .keyvisual,
    .keyvisual__big {
        height: calc(90vh - 80px /*HeaderHeight*/);
    }

}

.keyvisual .facts {
    align-items: center;
    margin-top: initial;
    margin-right: $spacing-mobile-s;
}

.house__facts-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //height: 40px;
    padding: 8px 12px 10px 12px;
    background-color: $color-tertiary-light;

    .price {
        align-self: flex-start;
        white-space: nowrap;
    }
}

@media(max-width: 1300px) {
    .keyvisual {
        margin-top: 110px;
    }
}

@include media-breakpoint-down(sm) {
    .keyvisual {
        margin-top: 80px;
    }

    .keyvisual-empty {
        height: calc(#{$header-height-mobile} + #{pxToRem(48)}) !important;
    }
}